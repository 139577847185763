import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from '../components/seo'
import TopicPagination from '../components/topicPagination'
import CloseButton from "../components/closeButton"
import ReactHtmlParser from "react-html-parser"
import Img from 'gatsby-image'
import GridCell from "../components/gridCell"
import Bucket from "../components/bucket"
import { sortTopics } from '../components/utils/topicUtils'
import '../components/topicLink.css'
import '../components/stats/wifi.css'
import '../components/stats/pipeline.css'
import '../components/stats/collab.css'
import '../components/stats/pullquotes.css'
import '../components/stats/numstat.css'
import '../components/fontawesome/css/solid.css'
import '../components/stats/orgchart.css'

const NonStretchedImage = props => {
  let normalizedProps = props
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: props.fluid.presentationWidth,
        margin: "0 auto", // Used to center the image
      },
    }
  }

  return <Img {...normalizedProps} />
}

function findAndReplace(string, target, replacement) {
  var i = 0, length = string.length;
  for (i; i < length; i++) {
    string = string.replace(target, replacement);
  }
 return string;
 }

export default ({data, location}) => {
  const topic = data.allNodeTopic.edges[0].node
  const bucket = topic.relationships.field_annual_report;
  let bucketName = ``;
  let bucketImage = ``;
  let bucketImageAlt = ``;
  let bucketIcon = [];
  let bucketIconAlt = ``;
  let bucketTopics = ``;
  // let topicImages = ``;
  let topic_body_elements = ``;

  if (bucket !== null){
    bucketName = bucket.name;
    bucketImage = (bucket.relationships.field_sidebar !== null ? bucket.relationships.field_sidebar : ``);
    bucketImageAlt = (bucket.field_sidebar_alt_text !== null ? bucket.field_sidebar_alt_text : ``);
    bucketIcon = (bucket.relationships.field_icon !== null ? bucket.relationships.field_icon : []);
    bucketIconAlt = (bucket.field_icon_alt_text !== null ? bucket.field_icon_alt_text : ``);
    bucketTopics = (bucket !== null ? sortTopics(bucket.relationships.node__topic) : ``)
  }
  
  if (topic !== null){
    // if(topic.relationships.field_topic_image !== null){
    //   topicImages = topic.relationships.field_topic_image;
    // }

    if(topic.body !== null){
      topic_body_elements = new ReactHtmlParser(topic.body.processed, {
        transform: function transform(node) {
          if (node.type === 'tag' && node.name === 'img') {
            let uuid = node.attribs["data-entity-uuid"];
            let alt = node.attribs["alt"];
            let classes = node.attribs["class"];
            let useGatsbyImage = (classes ? classes.split(" ").includes("no-gatsby-image") : false);
            let i = 0;
            for (i = 0; i < data.allFileFile.edges.length; i++) {              
              if (data.allFileFile.edges[i].node.drupal_id === uuid &&
                data.allFileFile.edges[i].node.localFile) {
                  // allow user to turn off gatsby-image use
                  if(useGatsbyImage){
                    return <img key={data.allFileFile.edges[i].node.drupal_internal__fid} 
                                alt={alt}
                                src={findAndReplace(data.allFileFile.edges[i].node.localFile.publicURL, "%20", "%2520")} 
                                className={classes} />
                  }
                  return <NonStretchedImage 
                    key={data.allFileFile.edges[i].node.drupal_internal__fid} 
                    alt={alt} 
                    className={classes}
                    fluid={data.allFileFile.edges[i].node.localFile.childImageSharp.fluid} />
                  }
            }
          }
      
          return undefined;
        }
      });
    }
  }

  return (
    <Layout>
      <SEO title={topic.title} keywords={[`gatsby`, `application`, `react`]} />
      <div className="topic-holder page-container">
          <div className="primary">
            <div className="page-bucket">
              <GridCell classNames={`bucket-grid-cell colourA`} tag='div'>
                <Bucket title={bucketName} images={bucketIcon} tag='h2' alt={bucketIconAlt} />
              </GridCell>
            </div>
          </div>
          <div className="secondary">
            <div className="page-controls">
              <CloseButton />
              <TopicPagination bucket={bucket} topics={bucketTopics} location={location} />
            </div>
            <div className="page-content">
              <div className="topic-content">
                <h3 className="topic-title">
                  <span className="label-wrap">
                    <span className="sr-only">Topic</span>
                    <span className="topiclink-index">{topic.orderLabel}</span>
                  </span>
                  {topic.title}
                </h3> 
                {/* {topicImages && topicImages.map((topicImage)  => (
                    <Img key={topicImage.drupal_internal__fid} fluid={topicImage.localFile.childImageSharp.fluid} />
                ))} */}
                <div className="topic-body">{topic_body_elements}</div>
              </div>
            </div>
          </div>
          <div className="tertiary">
            {bucketImage && bucketImage.localFile &&
              <Img alt={bucketImageAlt} fluid={bucketImage.localFile.childImageSharp.fluid} />}
          </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    allNodeTopic(filter:{fields:{slug:{eq: $slug}}}){
      edges {
          node {
            title
            orderLabel:field_topic_id
            path {
              alias
            }
            body {
                value
                processed
            }
            relationships {
              field_annual_report{
                name
                drupal_internal__tid
                path{
                  alias
                }
                field_icon_alt_text
                field_sidebar_alt_text
                relationships {
                  node__topic {
                    title
                    field_topic_id
                    path{
                      alias
                    }
                  }

                  field_icon{
                    id
                    drupal_internal__fid
                    filename
                    localFile {
                      publicURL
                      childImageSharp {
                        fluid(maxWidth: 200, maxHeight: 200) {
                        ...GatsbyImageSharpFluid
                        }
                        fixed(width: 100, height: 100) {
                          base64
                          tracedSVG
                          aspectRatio
                          width
                          height
                          src
                          srcSet
                          srcWebp
                          srcSetWebp
                          originalName
                        }
                      }
                    }
                  }

                  field_sidebar {
                    localFile {
                      childImageSharp {
                        fluid(
                          maxWidth: 800,
                          quality: 80) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                        fixed(width: 400) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }

                }
              }
            }
          }
      }
    }
    allFileFile{
      edges {
        node {
          drupal_id
          drupal_internal__fid
		  filename
          localFile {
			publicURL
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                ...GatsbyImageSharpFluid
                presentationWidth
              }
            }
          }
        }
      }
    }
  }  
`
